import styled from 'styled-components';
import React from 'react';

export const GlobalWrapperDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;
export const WrapperDoubleDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 33%;
`;

export const InputWrapper = styled.div`
  height: auto;
  min-height: 60px;
  min-width: 100px;
  width: auto;
  margin: 0 15px 0 0;`;

export const InputWrapperCard = styled.div`
  height: auto;
  min-height: 60px;
  width: 150px;
  margin: 0 15px 0 0;
`;

export const InlineText = styled.div`
  display: flex;
  border-color: transparent;
  border-width: 2px;
  border-style: solid;
  font-size: 14px;
  align-items: center;
  padding: 8px 6px;
  width: 150px;
`;

export const Text = ({ label, text }) => (
  <InputWrapper>
    <label
      style={{ fontWeight: 'bold' }}
      htmlFor={label}
    >
      {label}
    </label>
    <InlineText>
      {text}
    </InlineText>
  </InputWrapper>
);

export const Label = ({ label, isError }) => (
  <label
    style={{
      fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: isError ? 'red' : 'black',
    }}
    htmlFor={label}
  >
    {label}
  </label>
);
