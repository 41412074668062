export const RETRIEVE_OPERATING_AIRLINES_REQUEST = 'RETRIEVE_OPERATING_AIRLINES_REQUEST';
export const RETRIEVE_OPERATING_AIRLINES_RESPONSE = 'RETRIEVE_OPERATING_AIRLINES_RESPONSE';
export const RETRIEVE_OPERATING_AIRLINES_ERROR = 'RETRIEVE_OPERATING_AIRLINES_ERROR';
export const RETRIEVE_JURISDICTIONS_REQUEST = 'RETRIEVE_JURISDICTIONS_REQUEST';
export const RETRIEVE_JURISDICTIONS_RESPONSE = 'RETRIEVE_JURISDICTIONS_RESPONSE';
export const RETRIEVE_JURISDICTIONS_ERROR = 'RETRIEVE_JURISDICTIONS_ERROR';

export const retrieve_operated_airlines_request = (params) => ({
  type: RETRIEVE_OPERATING_AIRLINES_REQUEST,
  payload: params,
});

export const retrieve_operated_airlines_response = (operating_airlines) => ({
  type: RETRIEVE_OPERATING_AIRLINES_RESPONSE,
  payload: operating_airlines,
});

export const retrieve_operated_airlines_error = (error) => ({
  type: RETRIEVE_OPERATING_AIRLINES_ERROR,
  payload: error,
});

export const retrieve_jurisdictions_request = () => ({
  type: RETRIEVE_JURISDICTIONS_REQUEST,
});

export const retrieve_jurisdictions_response = (jurisdictions) => ({
  type: RETRIEVE_JURISDICTIONS_RESPONSE,
  payload: jurisdictions,
});

export const retrieve_jurisdictions_error = (error) => ({
  type: RETRIEVE_JURISDICTIONS_ERROR,
  payload: error,
});
