import { ajax } from 'rxjs/ajax';
import { map } from 'rxjs/operators';
import { Audience } from '../../domain/audience';
import { User } from '../../../usercontext/domain/user';

function mapAudienceIdEntity(data) {
  return Audience(data.response.data);
}

function isSelected(item) {
  return item.checked;
}

function mapAudienceEntity(data) {
  return data.response.data.map((e) => Audience(e));
}

function mapUserEntity(data) {
  return data.response.data.map((user) => User(user)).filter((user) => !user.email.includes('@deleted.fr'));
}

export function AudienceGateway() {
  return {
    retrieveAudience(search) {
      return ajax.get(`${process.env.REACT_APP_API_URL}/api/convocations${search}`, {
        'x-access-token': localStorage.getItem('admin-token'),
        'Content-Type': 'application/json',
      }).pipe(
        map((elem) => mapAudienceEntity(elem)),
      );
    },
    retrieveAudienceById(id) {
      return ajax.get(`${process.env.REACT_APP_API_URL}/api/hearingdate?id=${id}`, {
        'x-access-token': localStorage.getItem('admin-token'),
        'Content-Type': 'application/json',
      }).pipe(
        map((audience) => mapAudienceIdEntity(audience)),
      );
    },
    retrieveJurists() {
      return ajax.get(`${process.env.REACT_APP_API_URL}/api/auth/users`, {
        'x-access-token': localStorage.getItem('admin-token'),
        'Content-Type': 'application/json',
      }).pipe(
        map((user) => mapUserEntity(user)),
      );
    },
    addJurists(jurists, audienceId) {
      return ajax.post(`${process.env.REACT_APP_API_URL}/api/jurists/add`, {
        jurists: jurists ? jurists.map((jurist) => jurist.value) : [],
        convocationId: audienceId,
      }, {
        'x-access-token': localStorage.getItem('admin-token'),
        'Content-Type': 'application/json',
      });
    },
    retrieveMyAudiences(search) {
      return ajax.get(`${process.env.REACT_APP_API_URL}/api/convocation/me${search}`, {
        'x-access-token': localStorage.getItem('admin-token'),
        'Content-Type': 'application/json',
      }).pipe(
        map((audience) => mapAudienceEntity(audience)),
      );
    },
    updateNote(audienceId, note) {
      return ajax.post(`${process.env.REACT_APP_API_URL}/api/convocation/update/note`, {
        information: note,
        convocationId: audienceId,
      }, {
        'x-access-token': localStorage.getItem('admin-token'),
        'Content-Type': 'application/json',
      });
    },
    add(audience) {
      const data = {
        ...audience,
        customersId: audience.passengers.map((passenger) => ({
          _id: passenger._id,
          rg_number: passenger.rg_number,
          objectID: passenger.record.objectID,
          passenger,
        })),
        datetime: audience.hearingDate,
        jurisdiction: audience.jurisdiction,
        type: audience.type,
      };
      return ajax.post(`${process.env.REACT_APP_API_URL}/api/convocation/add`, {
        ...data,
      }, {
        'x-access-token': localStorage.getItem('admin-token'),
        'Content-Type': 'application/json',
      });
    },
    generateCCL(passengers) {
      return ajax({
        url: `${process.env.REACT_APP_API_URL}/api/download/zip/ccl`,
        method: 'POST',
        responseType: 'arraybuffer',
        progressObserver: '',
        body: {
          passengers,
        },
        headers: {
          'x-access-token': localStorage.getItem('admin-token'),
          'Content-Type': 'application/json',
        },
      });
    },
    generateZipAudience({passengers, type}) {
      const recordsId = passengers.filter(isSelected).map((item) => item.record._id);
      return ajax({
        url: `${process.env.REACT_APP_API_URL}/api/download/zip`,
        method: 'POST',
        responseType: 'arraybuffer',
        progressObserver: '',
        body: {
          recordsId,
          type,
        },
        headers: {
          'x-access-token': localStorage.getItem('admin-token'),
          'Content-Type': 'application/json',
        },
      });
    },
    updateAudienceToggle(audienceId) {
      return ajax.get(`${process.env.REACT_APP_API_URL}/api/audience/toggle?id=${audienceId}`, {
        'x-access-token': localStorage.getItem('admin-token'),
        'Content-Type': 'application/json',
      });
    },
    updateMultipleStatusAudience(status, list) {
      const recordsId = list.filter(isSelected).map((item) => item.record._id);
      return ajax({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/api/claim/update/status`,
        body: {claimIds: recordsId, status},
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': localStorage.getItem('admin-token'),
        },
      });
    },
    bulkUpdatePassengersCancelling(cancelling, list) {
      const paxToUpdate = list.filter(pax => pax.checked).map(pax => pax._id)
      return ajax({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/api/customers/updateBulk`,
        body: {
          customersId: paxToUpdate,
          customer: {
            cancelling
          }
        },
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': localStorage.getItem('admin-token'),
        },
      });
    },
  };
}
